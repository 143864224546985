import { variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import * as React from 'react';

const BetaStickerContainer = styled.svg(
  variant({
    defaultVariant: 'normal',
    base: {
      lineHeight: 0,
    },
    variants: {
      small: {
        height: 16,
        width: 32,
      },
      normal: {
        height: 26,
        width: 52,
      },
      medium: {
        height: 22,
        width: 42,
      },
    },
  })
);

type BetaStickerProps = {
  size?: 'normal' | 'small' | 'medium';
};

export const BetaSticker: React.FC<BetaStickerProps> = ({
  size = 'normal',
}) => {
  return (
    <BetaStickerContainer
      variant={size}
      viewBox="0 0 52 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Beta</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0.5H0V24.5V26.5H2H52V24.5H2V0.5Z"
        fill="currentColor"
      />
      <path
        d="M8 19.1H11.792C14.256 19.1 15.936 18.092 15.936 15.5C15.936 14.092 15.232 13.292 14.288 12.876C15.056 12.348 15.536 11.484 15.536 10.492C15.536 8.076 13.84 7.5 11.6 7.5H8V19.1ZM10.4 12.268V9.452H11.568C12.544 9.452 13.024 9.932 13.024 10.86C13.024 11.788 12.544 12.268 11.568 12.268H10.4ZM13.392 15.58C13.392 16.604 12.88 17.116 11.696 17.116H10.4V14.028H11.696C12.88 14.028 13.392 14.54 13.392 15.58Z"
        fill="currentColor"
      />
      <path
        d="M18.5677 19.1H25.5917V17.036H20.9677V14.204H25.2717V12.14H20.9677V9.564H25.5917V7.5H18.5677V19.1Z"
        fill="currentColor"
      />
      <path
        d="M31.1675 19.1H33.5675V9.564H36.8475V7.5H27.8875V9.564H31.1675V19.1Z"
        fill="currentColor"
      />
      <path
        d="M38.2312 19.1H40.7913L41.3193 16.684H44.0393L44.5673 19.1H47.1913L44.1992 7.5H41.2073L38.2312 19.1ZM42.6953 10.556L43.6233 14.812H41.7353L42.6953 10.556Z"
        fill="currentColor"
      />
    </BetaStickerContainer>
  );
};
